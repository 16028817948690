import Content from "@microsite/components/organisms/HeaderSection/Content";
import { PersonalizationSectionWithPseudoId } from "@utils/microsites/personalization/orderMicrositeSections";

interface Props {
  sectionData: PersonalizationSectionWithPseudoId;
}

const HeaderSection: React.FC<Props> = ({ sectionData }) => {
  return <Content sectionData={sectionData} />;
};

export default HeaderSection;
