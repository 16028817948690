"use client";

import { ArrowRightShort } from "react-bootstrap-icons";

import cn from "@utils/cn";

interface AdvancePlaylistArrowProps {
  moveForward: () => void;
}

const AdvancePlaylistArrow: React.FC<AdvancePlaylistArrowProps> = ({
  moveForward,
}) => {
  return (
    <button
      className={cn(
        "hidden laptop:flex",
        "absolute z-20 top-1/2 ml-[calc(425px+2*var(--sf-custom-padding,0))]",
        "w-14 h-14",
        "bg-base-content text-base shadow",
        "p-2 rounded-full justify-center items-center",
      )}
      onClick={moveForward}
    >
      <ArrowRightShort className="text-2xl" />
    </button>
  );
};

export default AdvancePlaylistArrow;
