"use client";

import { useContext } from "react";

import Card from "@customer-ui/components/Card";
import Typography from "@customer-ui/components/Typography";
import Stars from "@customer-ui/icons/Stars";
import ErrorBoundary from "@microsite/components/ErrorBoundary";
import CarouselPlaylist from "@microsite/components/molecules/CarouselPlaylist";
import StoriesBadge from "@microsite/components/molecules/StoriesBadge";
import DesktopCallToAction from "@microsite/components/molecules/StoriesDesktopCallToAction";
import ProductCard from "@microsite/components/organisms/StoriesCarouselSection/ProductCard";
import TextWithVariables from "@microsite/components/TextWithVariables";
import ThemeWrapper from "@microsite/components/ThemeWrapper";
import cn from "@utils/cn";
import useBackgroundImageStyle from "@utils/hooks/useBackgroundImageStyle";
import useBreakpointOrGreater from "@utils/hooks/useBreakpointOrGreater";
import { CustomerOnboardingCustomizationContext } from "@utils/microsites/contexts";
import pickSectionStories from "@utils/microsites/stories/pickSectionStories";
import { reviewsMessageFormatter } from "@utils/microsites/stories/reviewsMessageFormatter";
import { PersonalizationSectionStories } from "@utils/microsites/types/personalization";

interface Props {
  sectionData: PersonalizationSectionStories;
}

const StoriesCarouselSection: React.FC<Props> = ({ sectionData }) => {
  const { customerSettings } = useContext(
    CustomerOnboardingCustomizationContext,
  );

  const isGreaterThanLaptop = useBreakpointOrGreater("laptop");

  const backgroundStyles = useBackgroundImageStyle(
    sectionData?.backgroundMedia,
  );

  const title =
    customerSettings.intro || sectionData.title || "Check this out!";
  const subtitle = customerSettings.messages?.[0] || sectionData.subtitle || "";
  const reviewsMessage = reviewsMessageFormatter(sectionData.reviews || "");

  const stories = pickSectionStories(sectionData);

  const isWrappedContent = !!sectionData?.wrapContent;

  const theme =
    (sectionData.theme === "base") !== isWrappedContent ? "base" : "primary";

  return (
    <div
      className={cn(
        "block bg-base text-base-content",
        "laptop:flex laptop:flex-col laptop:overflow-hidden laptop:bg-inherit",
      )}
      style={
        sectionData.backgroundFull && isGreaterThanLaptop
          ? backgroundStyles
          : {}
      }
    >
      <div
        className={cn(
          "w-full pb-6 gap-4 pt-4 text-center",
          "flex flex-col items-center justify-center",
          "laptop:flex-row laptop:mx-auto laptop:h-screen",
          "laptop:max-h-[50rem] laptop:pb-0 laptop:pt-0",
        )}
      >
        <section className="flex laptop:hidden flex-col gap-2 max-w-2xl px-6 py-2">
          <Typography.Header2 className="leading-[34px]">
            <TextWithVariables value={title || ""} />
          </Typography.Header2>
        </section>

        <section className="laptop:hidden">
          <StoriesBadge theme={theme} />
        </section>

        <ThemeWrapper
          className="hidden laptop:flex w-1/2 self-stretch items-center justify-end"
          theme={sectionData.backgroundFull ? "inherit" : "base"}
        >
          <div
            className={cn(
              "w-full h-full flex justify-center items-center px-16",
              "1.5xl:w-[640px] 1.5xl:pl-0",
              "2xl:w-[683px]",
              "3xl:w-[720px]",
              "4xl:w-[768px]",
            )}
          >
            <div className="flex flex-col w-full">
              <Card
                className={cn(
                  "text-left mb-8 pb-[30px] pr-3.5 !border-transparent",
                  {
                    "bg-primary text-primary-content": isWrappedContent,
                    "bg-transparent text-base-content p-0": !isWrappedContent,
                  },
                )}
              >
                <ErrorBoundary>
                  <StoriesBadge
                    theme={
                      // This is a XOR between these conditions. We'll have base theme if they're both true or both false
                      (!sectionData.backgroundFull ||
                        sectionData.theme === "base") !== isWrappedContent
                        ? "base"
                        : "primary"
                    }
                  />
                </ErrorBoundary>

                {title && (
                  <h1
                    className={cn(
                      "font-display font-bold text-5xl leading-tight mt-3",
                      {
                        "mb-1": !!reviewsMessage,
                        "mb-4": !reviewsMessage,
                      },
                    )}
                  >
                    <TextWithVariables value={title} />
                  </h1>
                )}

                <div>
                  {reviewsMessage && (
                    <div className="mb-4 text-md flex items-center gap-1">
                      <Stars amount={5} />

                      <div className="font-semibold font-display ml-2 mt-0.5">
                        <TextWithVariables value={reviewsMessage} />
                      </div>
                    </div>
                  )}

                  {subtitle && (
                    <div className="italic mb-6 text-lg leading-tight font-normal font-body md:w-5/6 opacity-70">
                      <TextWithVariables value={subtitle} />
                    </div>
                  )}
                </div>
              </Card>

              <ErrorBoundary>
                <DesktopCallToAction sectionData={sectionData} />
              </ErrorBoundary>
            </div>
          </div>
        </ThemeWrapper>

        <section
          className={cn(
            "flex max-h-screen flex-col h-fit overflow-x-hidden w-full bg-base/0",
            "laptop:w-1/2 laptop:justify-center laptop:h-full",
            "desktop:pl-[5vw]",
          )}
          style={
            sectionData.backgroundFull || !isGreaterThanLaptop
              ? {}
              : backgroundStyles
          }
        >
          <ErrorBoundary>
            <CarouselPlaylist stories={stories} />
          </ErrorBoundary>
        </section>

        <section className="laptop:hidden w-full px-4 max-w-sm">
          <ErrorBoundary>
            <ProductCard sectionData={sectionData} />
          </ErrorBoundary>
        </section>

        {subtitle && (
          <section className="laptop:hidden px-6 tablet:px-16 max-w-3xl">
            <Typography.Header5 className="italic leading-[22px] font-normal">
              <TextWithVariables value={subtitle} />
            </Typography.Header5>
          </section>
        )}
      </div>
    </div>
  );
};

export default StoriesCarouselSection;
