"use client";

import { useContext } from "react";

import Header from "@microsite/components/molecules/Header";
import Section from "@microsite/components/Section";
import useCartState from "@microsite/state/cart";
import cn from "@utils/cn";
import { CampaignSuperfiliateMicrositeContext } from "@utils/microsites/contexts";
import findMicrositeSection from "@utils/microsites/personalization/findMicrositeSection";
import { PersonalizationSectionWithPseudoId } from "@utils/microsites/personalization/orderMicrositeSections";
import { StoriesVariation } from "@utils/microsites/personalization/sectionsVariationsEnums";
import { Sections } from "@utils/microsites/types/personalization";

interface Props {
  sectionData: PersonalizationSectionWithPseudoId;
}

const Content: React.FC<Props> = ({ sectionData }) => {
  const { personalization } = useContext(CampaignSuperfiliateMicrositeContext);
  const cartEnabled = useCartState((state) => state.isEnabled);

  if (sectionData.__typename !== "PersonalizationsMicrositeSectionsHeader")
    return null;

  const headerSection = findMicrositeSection(personalization, Sections.Header);

  const storiesSection = findMicrositeSection(
    personalization,
    Sections.Stories,
  );

  const storiesSectionVariation = storiesSection?.variation as StoriesVariation;

  const logoMediaId =
    headerSection?.logoType === "default_logo"
      ? personalization?.branding?.images?.logoMedia?.mediaId
      : personalization?.branding?.images?.altLogoMedia?.mediaId;

  if (!logoMediaId) return null;

  return (
    <div
      className={cn({
        "hidden laptop:block":
          storiesSectionVariation == StoriesVariation.INSTAGRAM,
      })}
    >
      <Section
        className={cn("items-center min-h-[4.5rem] max-w-screen-2xl flex", {
          "justify-between": cartEnabled,
          "justify-center": !cartEnabled,
        })}
        id={sectionData.pseudoId}
        paddingY="py-4"
        theme={sectionData.theme || "primary"}
        wrapperClassName="w-full"
      >
        <Header cart={cartEnabled} logoMediaId={logoMediaId!} />
      </Section>
    </div>
  );
};

export default Content;
