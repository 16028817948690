"use client";

import React, { useContext } from "react";

import TextWithVariables from "@microsite/components/TextWithVariables";
import usePromotionHeroBadgeEnabled from "@microsite/features/promotion/hooks/usePromotionHeroBadgeEnabled";
import usePromotionPersonalization from "@microsite/features/promotion/hooks/usePromotionPersonalization";
import discountToString from "@microsite/lib/discountToString";
import { LeadDiscount } from "@superfiliate/graphql-sdk/src/lib/__generated__";
import { CampaignSuperfiliateMicrositeContext } from "@utils/microsites/contexts";
import findMicrositeSection from "@utils/microsites/personalization/findMicrositeSection";
import { Sections } from "@utils/microsites/types/personalization";

const textBuilder = (
  customText: string | undefined,
  noDiscount: boolean,
  discount: string,
): string => {
  if (customText) {
    return customText;
  } else if (noDiscount) {
    return "Shop now";
  } else {
    return `Get ${discount} OFF`;
  }
};

const StoriesButtonText: React.FC = () => {
  const { campaignSuperfiliate, personalization } = useContext(
    CampaignSuperfiliateMicrositeContext,
  );

  const leadDiscount =
    campaignSuperfiliate.campaign.campaignReward!.leadDiscount;
  const leadDiscountType =
    campaignSuperfiliate.campaign.campaignReward!.leadDiscountType;

  const promotionPersonalization = usePromotionPersonalization();
  const promotionHeroBadgeEnabled = usePromotionHeroBadgeEnabled(
    promotionPersonalization,
  );
  const storiesSection = findMicrositeSection(
    personalization,
    Sections.Stories,
  );
  const customCtaButtonText = promotionHeroBadgeEnabled
    ? promotionPersonalization.heroBadgeText!
    : storiesSection?.ctaButton?.text;

  const discount = discountToString(leadDiscount);
  const noDiscount =
    !leadDiscount ||
    leadDiscount?.value === 0 ||
    leadDiscountType != LeadDiscount.OrderDiscount;

  return (
    <TextWithVariables
      value={textBuilder(customCtaButtonText, noDiscount, discount)}
    />
  );
};

export default StoriesButtonText;
