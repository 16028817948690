"use client";

import { useContext } from "react";

import Image from "@customer-ui/components/Image";
import {
  SafeString,
  useMustacheContent,
} from "@customer-ui/components/Mustache";
import ThemeWrapper, { ThemeColor } from "@microsite/components/ThemeWrapper";
import cn from "@utils/cn";
import { CampaignSuperfiliateMicrositeContext } from "@utils/microsites/contexts";
import findMicrositeSection from "@utils/microsites/personalization/findMicrositeSection";
import { Sections } from "@utils/microsites/types/personalization";

const BadgeWrapper: React.FC<
  React.PropsWithChildren<{
    theme?: ThemeColor;
    commonClasses: string;
  }>
> = ({ theme, commonClasses, children }) => {
  if (theme) {
    return (
      <ThemeWrapper className={commonClasses} theme={theme}>
        {children}
      </ThemeWrapper>
    );
  } else {
    return <div className={commonClasses}>{children}</div>;
  }
};

const Badge: React.FC<{
  theme?: ThemeColor;
  text: string;
  mediaId?: string | null;
}> = ({ theme, text, mediaId }) => {
  const commonClasses = cn(
    "border rounded-4xl inline-flex items-center gap-2 text-xs md:text-sm font-bold font-body truncate max-w-[calc(min(80vw,500px))]",
    {
      "py-3.5 px-[18px]": !mediaId,
      "pl-1 pr-6 py-1": !!mediaId,
      "bg-base text-base-content border-base-content": !!theme,
      "bg-[#222222]/60 backdrop-blur-xl text-white border-[#00000011]": !theme,
    },
  );

  return (
    <BadgeWrapper commonClasses={commonClasses} theme={theme}>
      {mediaId && (
        <Image
          alt="Hero badge"
          className="object-cover rounded-full size-8 md:size-10"
          height={40}
          src={mediaId}
          width={40}
        />
      )}

      <span className="truncate">{text}</span>
    </BadgeWrapper>
  );
};

const StoriesBadge: React.FC<{
  theme?: ThemeColor;
}> = ({ theme }) => {
  const { personalization } = useContext(CampaignSuperfiliateMicrositeContext);

  const storiesSection = findMicrositeSection(
    personalization,
    Sections.Stories,
  );

  const overlineMedia = storiesSection?.overlineMedia;
  const overlineText = storiesSection?.overlineText || "";

  const badgeText = useMustacheContent({
    htmlTemplate: overlineText as unknown as SafeString,
    // If the template tries to use a variable that is empty or doesnt exist, it returns an empty string.
    // So we can completely hide the badge, instead of presenting a strange text on it.
    // Eg. If the text is `From {{sf_first_name}} {{sf_last_name}}`, but the customer last name is empty.
    //     It returns a completely empty string, instead of just the text with the first name.
    fallback: true,
  });

  if (!badgeText) return null;

  return (
    <Badge mediaId={overlineMedia?.mediaId} text={badgeText} theme={theme} />
  );
};

export default StoriesBadge;
