export type ThemeColor =
  | "base"
  | "primary"
  | "secondary"
  | "color3"
  | "color4"
  | "inherit";

interface ThemeWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  theme: ThemeColor;
}

const THEME_CSS_VARIABLES: Record<string, Record<string, string>> = {
  primary: {
    "--sf-base-swappable": "var(--primary-color)",
    "--sf-base-content-swappable": "var(--primary-content-color)",
    "--sf-primary-swappable": "var(--base-color)",
    "--sf-primary-content-swappable": "var(--base-content-color)",
    "--sf-secondary-swappable": "var(--secondary-color)",
    "--sf-secondary-content-swappable": "var(--secondary-content-color)",
  },
  secondary: {
    "--sf-base-swappable": "var(--secondary-color)",
    "--sf-base-content-swappable": "var(--secondary-content-color)",
    "--sf-primary-swappable": "var(--base-color)",
    "--sf-primary-content-swappable": "var(--base-content-color)",
    "--sf-secondary-swappable": "var(--primary-color)",
    "--sf-secondary-content-swappable": "var(--primary-content-color)",
  },
  color3: {
    "--sf-base-swappable": "var(--color3)",
    "--sf-base-content-swappable": "var(--color3-content)",
    "--sf-primary-swappable": "var(--base-color)",
    "--sf-primary-content-swappable": "var(--base-content-color)",
    "--sf-color3-swappable": "var(--primary-color)",
    "--sf-color3-content-swappable": "var(--primary-content-color)",
  },
  color4: {
    "--sf-base-swappable": "var(--color4)",
    "--sf-base-content-swappable": "var(--color4-content)",
    "--sf-primary-swappable": "var(--base-color)",
    "--sf-primary-content-swappable": "var(--base-content-color)",
    "--sf-color4-swappable": "var(--primary-color)",
    "--sf-color4-content-swappable": "var(--primary-content-color)",
  },
  base: {
    "--sf-base-swappable": "var(--base-color)",
    "--sf-base-content-swappable": "var(--base-content-color)",
    "--sf-primary-swappable": "var(--primary-color)",
    "--sf-primary-content-swappable": "var(--primary-content-color)",
    "--sf-secondary-swappable": "var(--secondary-color)",
    "--sf-secondary-content-swappable": "var(--secondary-content-color)",
  },
  inherit: {},
};

const ThemeWrapper: React.FC<ThemeWrapperProps> = ({
  style,
  children,
  theme,
  ...rest
}) => {
  return (
    <div
      data-theme={theme}
      style={{
        ...THEME_CSS_VARIABLES[theme],
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default ThemeWrapper;
