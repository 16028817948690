import Image from "@customer-ui/components/Image";
import cn from "@utils/cn";

interface Props {
  mediaId: string;
}

const LoaderWithLogo = ({ mediaId }: Props) => {
  return (
    <div className={cn("flex flex-col w-screen items-center justify-center")}>
      <div className={cn("flex flex-col items-center justify-center")}>
        <div
          className={cn(
            "relative h-[190px] w-[190px] inset-0 m-auto flex flex-col items-center justify-center",
          )}
        >
          <svg
            className="animate-spin fill-current text-primary-content"
            height="191"
            viewBox="0 0 190 191"
            width="190"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_44635_83204)">
              <path d="M95 2.875C95 1.56332 96.0638 0.4969 97.3751 0.52968C116.601 1.0103 135.25 7.31707 150.84 18.6434C166.429 29.9697 178.19 45.7571 184.588 63.8937C185.025 65.1307 184.339 66.472 183.092 66.8773C181.844 67.2826 180.507 66.5984 180.069 65.3621C173.982 48.1792 162.825 33.2229 148.048 22.4862C133.27 11.7495 115.598 5.76078 97.3751 5.28124C96.0638 5.24674 95 4.18668 95 2.875Z" />
              <path
                d="M190 95.5C190 147.967 147.467 190.5 95 190.5C42.5329 190.5 0 147.967 0 95.5C0 43.0329 42.5329 0.5 95 0.5C147.467 0.5 190 43.0329 190 95.5ZM4.75 95.5C4.75 145.344 45.1563 185.75 95 185.75C144.844 185.75 185.25 145.344 185.25 95.5C185.25 45.6563 144.844 5.25 95 5.25C45.1563 5.25 4.75 45.6563 4.75 95.5Z"
                opacity="0.05"
              />
            </g>
            <defs>
              <clipPath id="clip0_44635_83204">
                <rect height="190" transform="translate(0 0.5)" width="190" />
              </clipPath>
            </defs>
          </svg>

          <div
            className={cn(
              "absolute w-[120px] h-[120px] flex items-center justify-center",
            )}
          >
            <Image
              alt="Logo"
              className="h-[36px] object-contain"
              height={36}
              placeholder={false}
              src={mediaId}
              width={160}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoaderWithLogo;
