import usePromotionPersonalization from "@microsite/features/promotion/hooks/usePromotionPersonalization";

const usePromotionBannerEnabled = (): boolean => {
  const promotionPersonalization = usePromotionPersonalization();

  return !!(
    promotionPersonalization.enabled &&
    promotionPersonalization.topBannerDisplay
  );
};

export default usePromotionBannerEnabled;
