"use client";

import React, { useState } from "react";

import Carousel from "@microsite/components/molecules/Carousel";
import AdvancePlaylistArrow from "@microsite/components/molecules/CarouselPlaylist/AdvancePlaylistArrow";
import Item from "@microsite/components/molecules/CarouselPlaylist/Item";
import cn from "@utils/cn";
import modulo from "@utils/fn/modulo";
import useBreakpointOrLower from "@utils/hooks/useBreakpointOrLower";
import { PartialStory } from "@utils/microsites/types/personalization";

interface Props {
  stories: PartialStory[];
}

const CarouselPlaylist: React.FC<Props> = ({ stories }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [move, setMove] = useState<(direction: number) => void>(
    () => (_direction: number) => {
      /**/
    },
  );

  const isMobile = useBreakpointOrLower("laptop");
  const isMultiple = stories.length > 1;

  const onActiveIndexChanged = (index: number) => {
    setActiveIndex(index);
  };

  const onMoveRegistered = (move: (direction: number) => void) => {
    setMove(() => move);
  };

  return (
    <section
      className={cn("relative", {
        "w-fit mx-auto laptop:mx-0": !isMultiple,
      })}
    >
      {isMultiple ? (
        <Carousel
          items={stories}
          onActiveIndexChanged={onActiveIndexChanged}
          onMoveRegistered={onMoveRegistered}
        >
          {(media, index, size) => (
            <Item
              isActive={index === activeIndex!}
              isMobile={isMobile}
              isNext={index === modulo(activeIndex! + 1, size)}
              isPrevious={index === modulo(activeIndex! - 1, size)}
              media={media}
              moveBackward={() => move?.(1)}
              moveForward={() => move?.(-1)}
            />
          )}
        </Carousel>
      ) : (
        <Item
          isMobile={isMobile}
          media={stories[0]}
          isActive
          isNext
          isPrevious
        />
      )}

      {isMultiple && <AdvancePlaylistArrow moveForward={() => move?.(-1)} />}
    </section>
  );
};

export default CarouselPlaylist;
