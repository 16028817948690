"use client";

import Content from "@microsite/components/organisms/StoriesCarouselSection/Content";
import { PersonalizationSectionStories } from "@utils/microsites/types/personalization";

interface Props {
  sectionData: PersonalizationSectionStories;
}

const StoriesCarouselSection: React.FC<Props> = ({ sectionData }) => {
  return <Content sectionData={sectionData} />;
};

export default StoriesCarouselSection;
