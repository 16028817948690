"use client";

import { RefObject, useRef, useState } from "react";

/**
 * This hook is used to keep track of the React state when
 * using event listeners
 *
 * It is useful when you want to update the state of a component
 * when an event is fired, or read the state of a component when
 * an event is fired
 *
 * See more - https://stackoverflow.com/a/55265764
 *
 * @param initialState - The initial state of the component
 */
const useListenersState = <T>(
  initialState: T,
): [RefObject<T>, (_: T) => void] => {
  const [value, setValue] = useState<T>(initialState);

  const DOMState = useRef<T>(value);

  const setDOMState = (newValue: T) => {
    DOMState.current = newValue;

    setValue(newValue);
  };

  return [DOMState, setDOMState];
};

export default useListenersState;
