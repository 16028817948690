"use client";

import { FC, useContext } from "react";

import Button from "@customer-ui/components/Button";
import Image from "@customer-ui/components/Image";
import StoryFooter from "@customer-ui/components/MicrositeStoryFooter";
import { Transition } from "@headlessui/react";
import StoriesButtonText from "@microsite/components/organisms/StoriesButtonText";
import TextWithVariables from "@microsite/components/TextWithVariables";
import { ProductsContext } from "@microsite/contexts/products";
import scrollToProductSection from "@microsite/lib/navigation/scrollToProductSection";
import { reviewsMessageFormatter } from "@utils/microsites/stories/reviewsMessageFormatter";
import { PersonalizationSectionStories } from "@utils/microsites/types/personalization";

interface FloatingCardProps {
  sectionData: PersonalizationSectionStories;
}

const FloatingCard: FC<FloatingCardProps> = ({ sectionData }) => {
  const { product } = useContext(ProductsContext);

  const title = product?.title || "";

  const reviews = sectionData?.reviews || "";
  const reviewsMessage = reviewsMessageFormatter(reviews);

  const mediaId = product?.pickedMedias?.[0]?.mediaId;

  return (
    <Transition
      as="div"
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      show={!!product}
      appear
    >
      <StoryFooter
        className="bg-primary/100 text-primary-content"
        image={
          <Image
            alt="promoted product card image"
            className="rounded-0.5-sf-radius object-cover"
            height={65}
            src={mediaId || "#"}
            width={65}
          />
        }
        reviewsMessage={
          reviewsMessage && (
            <TextWithVariables
              className="text-primary-content"
              value={reviewsMessage}
            />
          )
        }
        title={title && <TextWithVariables value={title} />}
      >
        <Button
          className="text-xs 2xs:text-sm font-semibold !px-3 min-w-[114px]"
          color="base"
          onClick={scrollToProductSection}
        >
          <StoriesButtonText />
        </Button>
      </StoryFooter>
    </Transition>
  );
};

export default FloatingCard;
