import React, { ReactNode } from "react";

import cn from "@utils/cn";

type OpacityType = 0 | 20 | 40 | 60 | 80 | 100;

interface Props {
  loading: boolean;
  animate?: boolean;
  fullScreen?: boolean;
  opacity?: OpacityType;
  children?: ReactNode;
}

const OPACITY_VARIANTS = {
  0: "opacity-0",
  20: "opacity-20",
  40: "opacity-40",
  60: "opacity-60",
  80: "opacity-80",
  100: "opacity-100",
};

const LoadingOverlay = ({
  loading,
  fullScreen = false,
  opacity = 40,
  children,
  animate = true,
}: Props) => {
  return (
    <div
      className={cn({
        "animate-pulse": loading && animate,
        "fixed bottom-0 left-0 top-0 right-0": fullScreen,
        "h-screen w-full flex flex-col justify-center items-center gap-8":
          fullScreen,
        "z-[100] backdrop-blur-xl": fullScreen,
      })}
    >
      <div
        className={cn("transition-opacity", {
          [OPACITY_VARIANTS[opacity]]: loading,
          "pointer-events-none": loading,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default LoadingOverlay;
