"use client";

import { PropsWithChildren } from "react";
import {
  ErrorBoundary as ErrorBoundaryOriginal,
  FallbackProps,
} from "react-error-boundary";

const useShowError = (): boolean => {
  if (process.env.NODE_ENV === "development") return true;
  if (typeof window === "undefined") return false;

  const host = window.location.host;
  return host.includes(".preview.superfiliate.com");
};

const Fallback: React.FC<FallbackProps> = ({ error }) => {
  const showError = useShowError();

  return (
    <div data-error-boundary={error.toString()}>
      {showError &&
        "⚠️⚠️⚠️ Something went wrong, inspect for more details! ⚠️⚠️⚠️"}
    </div>
  );
};

const ErrorBoundary: React.FC<PropsWithChildren> = ({ children }) => (
  <ErrorBoundaryOriginal
    FallbackComponent={Fallback}
    onError={(error, info) => {
      // eslint-disable-next-line no-console
      console.warn(error, info.componentStack);
    }}
  >
    {children}
  </ErrorBoundaryOriginal>
);

export default ErrorBoundary;
