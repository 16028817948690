import React, { FC } from "react";
import { Icon } from "react-bootstrap-icons";

import Button, { ButtonProps } from "@customer-ui/components/Button";
import cn from "@utils/cn";

export type ButtonWithIconProps = React.PropsWithChildren<
  ButtonProps & { icon?: Icon; iconPosition?: "left" | "right" }
>;

const ButtonWithIcon: FC<ButtonWithIconProps> = ({
  icon: Icon,
  children,
  iconPosition = "left",
  ...rest
}) => {
  const iconPositionLeft = iconPosition === "left" || !iconPosition;
  const cnIcon = cn({
    "mr-[0.4em]": iconPositionLeft && children,
    "ml-[0.4em]": !iconPositionLeft && children,
  });

  return (
    <Button {...rest}>
      <span className={cn("mx-auto flex items-center justify-center")}>
        {Icon && iconPositionLeft && <Icon className={cnIcon} size={18} />}
        {children}
        {Icon && !iconPositionLeft && <Icon className={cnIcon} size={18} />}
      </span>
    </Button>
  );
};

export default ButtonWithIcon;
