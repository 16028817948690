import {
  CampaignSuperfiliateMicrosite,
  MicrositeExperience,
} from "../types/microsite";
import {
  PersonalizationSection,
  PersonalizationSectionFooter,
  PersonalizationSectionHeader,
  PersonalizationSectionProduct,
  PersonalizationSectionStories,
} from "../types/personalization";

const buildFallbackSections: () => PersonalizationSection[] = () => {
  return [
    {
      __typename: "PersonalizationsMicrositeSectionsHeader",
      kind: "header",
      variation: "default",
      theme: "primary",
      logoType: "alt_logo",
    } as PersonalizationSectionHeader,
    {
      __typename: "PersonalizationsMicrositeSectionsStories",
      kind: "stories",
      variation: "instagram",
    } as PersonalizationSectionStories,
    {
      __typename: "PersonalizationsMicrositeSectionsProduct",
      kind: "product",
      variation: "default",
      imageGridFormat: "square",
      theme: "base",
      multiSkuProductVariantIds: [],
      multiSkuAvailableProductVariantIds: [],
      skuSelectorStyle: "circles",
      ctaCaptionItems: [
        {
          htmlText:
            "Get {{sf_lead_discount}} off on your <strong>first order</strong>",
        },
      ],
    } as PersonalizationSectionProduct,
    {
      __typename: "PersonalizationsMicrositeSectionsFooter",
      kind: "footer",
      variation: "default",
      theme: "primary",
      logoType: "alt_logo",
    } as PersonalizationSectionFooter,
  ];
};

const FORCED_START_SECTIONS = ["header;0", "stories;0"];
const FORCED_ENDING_SECTIONS = ["footer;0"];

const DEFAULT_REORDERABLE_SECTIONS = [
  "product;0",
  "review;0",
  "media_with_text;0",
  "image;0",
  "video;0",
  "accordion;0",
  "showcase_images;0",
  "text;0",
];

export type PersonalizationSectionWithPseudoId = PersonalizationSection & {
  pseudoId: string;
};

const orderMicrositeSections = (
  experience: MicrositeExperience | CampaignSuperfiliateMicrosite,
): PersonalizationSectionWithPseudoId[] => {
  const { personalization } = experience;

  const sectionsOrder = Array.from(
    // Deduplicate entries
    new Set(
      [
        FORCED_START_SECTIONS,
        personalization.microsite?.sectionsOrder ||
          DEFAULT_REORDERABLE_SECTIONS,
        FORCED_ENDING_SECTIONS,
      ].flat(),
    ),
  );

  const sections = [
    personalization.microsite?.sections || [],
    buildFallbackSections(),
  ].flat();

  const mappedSections = sections.reduce(
    (mapping, section) => {
      mapping[section.kind] = mapping[section.kind] || [];
      mapping[section.kind].push(section);
      return mapping;
    },
    {} as Record<string, PersonalizationSection[]>,
  );

  return sectionsOrder
    .map((pseudoId) => {
      const [kind, index] = pseudoId.split(";");
      const section = mappedSections[kind]?.[parseInt(index)];
      if (!section) return null;

      return { pseudoId: pseudoId, ...section };
    })
    .filter(
      (section): section is PersonalizationSectionWithPseudoId => !!section,
    );
};

export default orderMicrositeSections;
