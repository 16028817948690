import React from "react";

import Mustache from "@customer-ui/components/Mustache";

interface Props extends React.PropsWithChildren {
  variables?: {
    sfShopName: string | undefined | null;
    sfFirstName: string | undefined | null;
    sfLastName: string | undefined | null;
    sfCode: string | undefined | null;
    sfLeadDiscount: string | undefined | null;
    sfMinimumCartValue: number | undefined | null;
  };
}

const DEFAULT_VARIABLES = {
  sfShopName: "Your Brand",
  sfFirstName: "Jane",
  sfLastName: "Doe",
  sfCode: "CODE",
  sfLeadDiscount: "$10",
  sfMinimumCartValue: 25,
};

const VariablesProvider: React.FC<Props> = ({
  variables = DEFAULT_VARIABLES,
  children,
}) => (
  <Mustache.Context.Provider value={variables}>
    {children}
  </Mustache.Context.Provider>
);

export default VariablesProvider;
