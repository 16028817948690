import { PersonalizationAppPromotion } from "@utils/microsites/types/personalization";

const usePromotionHeroBadgeEnabled = (
  promotionPersonalization: PersonalizationAppPromotion,
): boolean => {
  return !!(
    promotionPersonalization.enabled &&
    promotionPersonalization.heroBadgeDisplay &&
    promotionPersonalization.heroBadgeText
  );
};

export default usePromotionHeroBadgeEnabled;
