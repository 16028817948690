import dynamic from "next/dynamic";
import React from "react";

import GenericSectionLoader from "@microsite/components/molecules/GenericSectionLoader";
import LazySectionContainer from "@microsite/components/molecules/LazySectionContainer";
import { PersonalizationSectionWithPseudoId } from "@utils/microsites/personalization/orderMicrositeSections";

const Content = dynamic(
  () => import("components/organisms/MediaWithTextSection/Content"),
  { loading: () => <GenericSectionLoader /> },
);

interface Props {
  sectionData: PersonalizationSectionWithPseudoId;
}

const MediaWithText: React.FC<Props> = ({ sectionData }) => {
  return (
    <LazySectionContainer>
      <Content sectionData={sectionData} />
    </LazySectionContainer>
  );
};

export default MediaWithText;
