"use client";

import { useContext } from "react";

import Button from "@customer-ui/components/Button";
import Card from "@customer-ui/components/Card";
import Typography from "@customer-ui/components/Typography";
import MediaPlaylist from "@microsite/components/molecules/MediaPlaylist";
import StoriesBadge from "@microsite/components/molecules/StoriesBadge";
import DesktopCallToAction from "@microsite/components/molecules/StoriesDesktopCallToAction";
import ReviewsStar from "@microsite/components/organisms/StoriesBasicSection/ReviewsStar";
import StoriesButtonText from "@microsite/components/organisms/StoriesButtonText";
import TextWithVariables from "@microsite/components/TextWithVariables";
import ThemeWrapper from "@microsite/components/ThemeWrapper";
import usePromotionBannerEnabled from "@microsite/features/promotion/hooks/usePromotionBannerEnabled";
import scrollToProductSection from "@microsite/lib/navigation/scrollToProductSection";
import cn from "@utils/cn";
import { CustomerOnboardingCustomizationContext } from "@utils/microsites/contexts";
import { StoriesVariation } from "@utils/microsites/personalization/sectionsVariationsEnums";
import { pickSectionStories } from "@utils/microsites/stories";
import { reviewsMessageFormatter } from "@utils/microsites/stories/reviewsMessageFormatter";
import { PersonalizationSectionStories } from "@utils/microsites/types/personalization";

interface MobileContentProps {
  sectionData: PersonalizationSectionStories;
}

const StoriesBasicSection: React.FC<MobileContentProps> = ({ sectionData }) => {
  const { customerSettings } = useContext(
    CustomerOnboardingCustomizationContext,
  );

  const promotionBannerEnabled = usePromotionBannerEnabled();

  const playlist = pickSectionStories(sectionData);
  const title =
    customerSettings.intro || sectionData.title || "Check this out!";
  const subtitle = customerSettings.messages?.[0] || sectionData.subtitle;

  const reviews = sectionData?.reviews || "";
  const reviewsMessage = reviewsMessageFormatter(reviews);

  const isWrappedContent = !!sectionData?.wrapContent;

  return (
    <div className="bg-base">
      <section className="laptop:flex">
        <ThemeWrapper className="hidden laptop:w-1/2 laptop:block" theme="base">
          <div
            className={cn(
              "w-full h-full flex justify-center items-center",
              "p-10 xl:pr-16 xl:pl-[208px]",
            )}
          >
            <div className="flex flex-col w-full">
              <Card
                className={cn(
                  "text-left pb-8 pr-3.5 !border-transparent mb-8",
                  {
                    "bg-primary text-primary-content": isWrappedContent,
                    "bg-transparent text-base-content p-0": !isWrappedContent,
                  },
                )}
              >
                <div className="mb-6">
                  <StoriesBadge
                    theme={!isWrappedContent ? "base" : "primary"}
                  />
                </div>

                <div className="flex flex-col gap-4">
                  <Typography.Header1 className="!text-6xl break-words">
                    <TextWithVariables value={title} />
                  </Typography.Header1>

                  {reviewsMessage && <ReviewsStar reviews={reviews} />}

                  {subtitle && (
                    <Typography.Body2 className="font-semibold">
                      <TextWithVariables value={subtitle} />
                    </Typography.Body2>
                  )}
                </div>
              </Card>

              <DesktopCallToAction sectionData={sectionData} />
            </div>
          </div>
        </ThemeWrapper>

        <div
          className={cn(
            "overflow-hidden max-h-[50rem]",
            "laptop:w-1/2 laptop:h-[50vw]",
            {
              "h-[60vh]": !promotionBannerEnabled,
              "h-[calc(60vh-49px)]": promotionBannerEnabled,
            },
          )}
        >
          <div className="relative size-full overflow-hidden">
            <MediaPlaylist
              loopMedia={playlist.length === 1}
              playlist={playlist}
              style={StoriesVariation.BASIC}
              loopPlaylist
            />
          </div>
        </div>
      </section>

      <div className="p-4 max-w-[500px] mx-auto laptop:hidden">
        <div className="flex flex-col gap-3 mb-8 text-base-content">
          <div>
            <StoriesBadge theme={sectionData.theme} />
          </div>

          <Typography.Display1 className="!font-semibold">
            <TextWithVariables value={sectionData.title || ""} />
          </Typography.Display1>

          {sectionData.reviews && <ReviewsStar reviews={sectionData.reviews} />}

          {subtitle && (
            <Typography.Header6>
              <TextWithVariables value={subtitle} />
            </Typography.Header6>
          )}
        </div>

        <Button className="w-full" onClick={scrollToProductSection}>
          <StoriesButtonText />
        </Button>
      </div>
    </div>
  );
};

export default StoriesBasicSection;
