import React from "react";

import Typography from "@customer-ui/components/Typography";
import Stars from "@customer-ui/icons/Stars";
import { reviewsMessageFormatter } from "@utils/microsites/stories/reviewsMessageFormatter";

interface ReviewsStarProps {
  reviews: string;
}

const ReviewsStar: React.FC<ReviewsStarProps> = ({ reviews }) => {
  return (
    <div className="flex items-center">
      <Stars amount={5} />

      <Typography.Body4 className="!font-semibold ml-1 md:!text-md">
        {reviewsMessageFormatter(reviews)}
      </Typography.Body4>
    </div>
  );
};

export default ReviewsStar;
