"use client";

import { FC, PropsWithChildren, useState, useEffect } from "react";

import { FadeInContainer } from "@microsite/components/FadeInContainer";
import cn from "@utils/cn";

interface LazySectionContainerProps extends PropsWithChildren {
  delay?: number;
  eager?: boolean;
}

/**
 * A high order component to wrap any component that must be rendered on the
 * screen only when the user scrolls until the given section
 *
 * By leveraging the IntersectionObserver, it detects whether the customer arrived
 * at the given component, and then starts the rendering phase
 *
 * It can be used in combination with React.Suspense, so the splitted JS file
 * is loaded only when in viewport
 *
 * It also accepts a delay parameter, in case the component must be delayed
 * prior to it's rendering
 *
 * @resources - https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 */
const LazySectionContainer: FC<LazySectionContainerProps> = ({
  children,
  delay = 700,
}) => {
  const [isInViewport, setIsInViewport] = useState(false);
  const [isDelayComplete, setIsDelayCompleted] = useState(false);

  useEffect(() => {
    if (!delay) return;

    const timeoutId = setTimeout(() => {
      setIsDelayCompleted(true);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [delay, setIsDelayCompleted]);

  const isVisible = isInViewport || isDelayComplete;

  return (
    <>
      <div />

      <FadeInContainer
        className={cn({
          "h-[50vh]": !isVisible,
          "h-fit": isVisible,
        })}
        show={isVisible}
        onViewportEnter={() => setIsInViewport(true)}
      >
        {isVisible ? children : null}
      </FadeInContainer>

      <div />
    </>
  );
};

export default LazySectionContainer;
