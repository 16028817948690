import Content from "@microsite/components/organisms/StoriesSection/Content";
import { PersonalizationSectionWithPseudoId } from "@utils/microsites/personalization/orderMicrositeSections";

interface Props {
  sectionData: PersonalizationSectionWithPseudoId;
}

const StoriesIntro: React.FC<Props> = ({ sectionData }) => {
  return <Content sectionData={sectionData} />;
};

export default StoriesIntro;
