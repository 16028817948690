"use client";

import React from "react";
import { useSwipeable } from "react-swipeable";

interface SwipeableContainerProps extends React.PropsWithChildren {
  onSwipeForward: () => void;
  onSwipeBackward: () => void;
}

const SwipeableContainer: React.FC<SwipeableContainerProps> = ({
  onSwipeBackward,
  onSwipeForward,
  children,
}) => {
  const onTap = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    const clickPosition = event.offsetX;
    const elementDimensions = target.getBoundingClientRect();

    const elementPart = elementDimensions.width / 5;

    const clickLeft = clickPosition < elementPart;
    const clickRight = clickPosition > elementPart * 4;

    if (clickLeft) return onSwipeBackward();
    if (clickRight) return onSwipeForward();
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: onSwipeForward,
    onSwipedRight: onSwipeBackward,
    onTap: ({ event }) => {
      if (event instanceof MouseEvent) return onTap(event);
    },
    preventScrollOnSwipe: true,
    trackMouse: true,
    delta: 100,
  });

  return (
    <section
      style={{
        height: "inherit",
        maxHeight: "inherit",
        width: "inherit",
        maxWidth: "inherit",
      }}
      {...swipeHandlers}
    >
      {children}
    </section>
  );
};

export default SwipeableContainer;
