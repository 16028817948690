import {
  BaseValueUnit,
  Unit,
} from "@superfiliate/graphql-sdk/src/lib/__generated__/microsite";
import { getCurrency } from "@utils/formatters/currency";

const formatter = () =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: getCurrency(),
    minimumFractionDigits: 2,
  });

const intFormatter = () =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: getCurrency(),
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

const discountToString = (input: BaseValueUnit): string => {
  if (!input) return "$0";

  const { unit, value } = input;

  if (unit === Unit.Percentage) {
    return `${value}%`;
  }

  return value % 1 === 0
    ? intFormatter().format(value)
    : formatter().format(value);
};

export default discountToString;
