"use client";
/* TODO: Migrate this component tree to be a server component */

import StoriesBasicSection from "@microsite/components/organisms//StoriesBasicSection";
import StoriesCarouselSection from "@microsite/components/organisms/StoriesCarouselSection";
import StoriesInstagramSection from "@microsite/components/organisms/StoriesInstagramSection";
import ThemeWrapper from "@microsite/components/ThemeWrapper";
import { PersonalizationSectionWithPseudoId } from "@utils/microsites/personalization/orderMicrositeSections";
import { StoriesVariation } from "@utils/microsites/personalization/sectionsVariationsEnums";

interface Props {
  sectionData: PersonalizationSectionWithPseudoId;
}

const VARIANTS = {
  [StoriesVariation.BASIC]: StoriesBasicSection,
  [StoriesVariation.CAROUSEL]: StoriesCarouselSection,
  [StoriesVariation.INSTAGRAM]: StoriesInstagramSection,
};

const Content: React.FC<Props> = ({ sectionData }) => {
  if (sectionData.__typename !== "PersonalizationsMicrositeSectionsStories")
    return null;

  const variation = sectionData.variation as StoriesVariation;

  const Component = VARIANTS[variation];

  return (
    <ThemeWrapper id={sectionData.pseudoId} theme={sectionData.theme}>
      <Component sectionData={sectionData} />
    </ThemeWrapper>
  );
};

export default Content;
