"use client";

import { FC, useEffect } from "react";

import useAttributionWindow from "@microsite/lib/hooks/useAttributionWindow";
import { PageProps } from "@utils/microsites/types/microsite";

const AttributionWindowScripts: FC<PageProps> = ({
  campaignSuperfiliateMicrosite,
}) => {
  const { setPageAccessCookie, sendPageAccessEvent } = useAttributionWindow();
  const shop = campaignSuperfiliateMicrosite?.shop;

  const shopId = shop?.id;
  const code = campaignSuperfiliateMicrosite?.campaignSuperfiliate?.code;

  useEffect(
    () => {
      setPageAccessCookie();
      if (shopId && code) sendPageAccessEvent(shopId, code);
    },
    // @TODO: PLEASE FIX: update hook deps accordingly when touching this file
    // Ref: https://react.dev/learn/removing-effect-dependencies#dependencies-should-match-the-code
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shopId, code],
  );

  return null;
};

export default AttributionWindowScripts;
