"use client";

import Script from "next/script";
import PlausibleProvider from "next-plausible";
import { FC, useEffect } from "react";

import {
  buildGTagManagerScript,
  buildGTagScript,
  useTripleWhale,
} from "@utils/analytics/buildAnalyticsToolsScripts";
import { initPosthog } from "@utils/analytics/posthog";
import { switchEnv } from "@utils/env";
import isClient from "@utils/isClient";
import { PageProps } from "@utils/microsites/types/microsite";

const ThirdPartyScripts: FC<PageProps> = (props) => {
  const initAnalytics = async () => {
    // Does not init PostHog analytics for Checkly checks
    if (userAgent && userAgent.toLowerCase().includes("checkly")) return;

    initPosthog(process.env.NEXT_PUBLIC_POSTHOG_API_KEY || "", "microsite", {
      recordSession: true,
      onLoaded: (posthog) => {
        posthog.group("shop", `id:${shop.id}`, {
          id: shop.id,
          name: shop.name,
          shopifyDomain: shop.shopifyDomain,
        });
      },
    });
  };

  useEffect(
    () => {
      if (!props.preview) {
        initAnalytics();
      } else {
        Array.from(document.getElementsByTagName("a")).map((tag) => {
          tag.addEventListener("click", (event) => {
            /* Disable all external links at preview mode */
            event.preventDefault();
          });
        });

        document.querySelector("body")?.classList?.add("no-scrollbar");
      }
    },
    // @TODO: PLEASE FIX: update hook deps accordingly when touching this file
    // Ref: https://react.dev/learn/removing-effect-dependencies#dependencies-should-match-the-code
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const shop = props?.campaignSuperfiliateMicrosite?.shop;

  useTripleWhale(shop?.setting?.tripleWhaleConfigured && shop.shopifyDomain);

  if (props.preview || !props.campaignSuperfiliateMicrosite) return null;

  const campaignId =
    props.campaignSuperfiliateMicrosite.campaignSuperfiliate.campaign.id;
  const subdomain = (props.host || "").replaceAll(".", "-");
  const shopId = shop.id;

  const rollups = [shopId, campaignId, subdomain]
    .reduce(
      (acc, item, idx) => [...acc, `${acc[idx]}/${item}`],
      ["rollup.superfiliate.com"],
    )
    .join(",");

  const plausibleDomain = `${props.host},rollup.portals.superfiliate.com,${rollups}`;

  const userAgent = props?.userAgent;

  return (
    <PlausibleProvider domain={plausibleDomain} taggedEvents trackOutboundLinks>
      {/*
        We just need to load this once, even if we have multiple GA accounts to be tracked.
        This loads the script, but doesn't execute much with it.
        We choose to use our own GA ID here, as this one certainly will always be present.
       */}
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-F0YGZ8MZ3V"
        strategy="lazyOnload"
      />

      {switchEnv({
        production: () => {
          const name = isClient
            ? new URL(window.location.href).pathname.replace("/", "")
            : ""; // Superfiliate Code

          return (
            <Script
              id="google-analytics"
              strategy="lazyOnload"
              // This executes the necessary initialization for Google Analytics, after the script loaded above.
              // It will set it up to track on both OUR GA, but also the Shop's GA if that has been configured.
            >
              {buildGTagScript(
                shop?.setting?.googleAnalyticsId,
                shop?.shopifyDomain,
                {
                  source: "superfiliate",
                  medium: "microsite",
                  name,
                },
              )}
            </Script>
          );
        },
        preview: null,
        development: null,
      })}

      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
        // This executes the necessary initialization for OUR Google Tag Manager, with the `gtag` script loaded above.
      >
        {buildGTagManagerScript("GTM-5P8HL2C7")}
      </Script>

      <noscript>
        <iframe
          height="0"
          src="https://www.googletagmanager.com/ns.html?id=GTM-5P8HL2C7"
          style={{ display: "none", visibility: "hidden" }}
          width="0"
        ></iframe>
      </noscript>
    </PlausibleProvider>
  );
};

export default ThirdPartyScripts;
