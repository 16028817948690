export enum ENV {
  Development = "development",
  Preview = "preview",
  Production = "production",
}

export function getCurrentEnv() {
  const env =
    process.env.NEXT_PUBLIC_VERCEL_ENV ??
    process.env.NODE_ENV ??
    ENV.Production;

  const options: string[] = Object.values(ENV);

  if (!options.includes(env)) {
    throw new Error(`Unexpected environment: ${env}`);
  }

  return env as ENV;
}

/**  Switch for running logic and returning values based on current process environment.
 * This helps to be intentional about what exactly should happen for each env. */
export function switchEnv<T>(envsRun: { [env in ENV]: () => T }): T;
export function switchEnv<T>(envsRun: { [env in ENV]: (() => T) | null }): null;
export function switchEnv<T>(envsRun: {
  [env in ENV]: (() => T) | null;
}): T | null {
  const env = getCurrentEnv();
  const res = envsRun[env];

  if (res === null) return null;
  return res();
}
