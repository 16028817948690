"use client";

import { useContext } from "react";

import Card from "@customer-ui/components/Card";
import Stars from "@customer-ui/icons/Stars";
import MediaPlaylist from "@microsite/components/molecules/MediaPlaylist";
import PhoneFrame from "@microsite/components/molecules/PhoneFrame";
import StoriesBadge from "@microsite/components/molecules/StoriesBadge";
import DesktopCallToAction from "@microsite/components/molecules/StoriesDesktopCallToAction";
import TextWithVariables from "@microsite/components/TextWithVariables";
import ThemeWrapper from "@microsite/components/ThemeWrapper";
import usePromotionBannerEnabled from "@microsite/features/promotion/hooks/usePromotionBannerEnabled";
import cn from "@utils/cn";
import useBackgroundImageStyle from "@utils/hooks/useBackgroundImageStyle";
import { CustomerOnboardingCustomizationContext } from "@utils/microsites/contexts";
import { StoriesVariation } from "@utils/microsites/personalization/sectionsVariationsEnums";
import pickSectionStories from "@utils/microsites/stories/pickSectionStories";
import { reviewsMessageFormatter } from "@utils/microsites/stories/reviewsMessageFormatter";
import { PersonalizationSectionStories } from "@utils/microsites/types/personalization";

interface Props {
  sectionData: PersonalizationSectionStories;
}

const StoriesInstagramSection: React.FC<Props> = ({ sectionData }) => {
  const { customerSettings } = useContext(
    CustomerOnboardingCustomizationContext,
  );

  const promotionBannerEnabled = usePromotionBannerEnabled();
  const backgroundStyles = useBackgroundImageStyle(
    sectionData?.backgroundMedia,
  );

  const title =
    customerSettings.intro || sectionData.title || "Check this out!";
  const subtitle = customerSettings.messages?.[0] || sectionData.subtitle || "";
  const reviews = sectionData?.reviews || "";
  const reviewsMessage = reviewsMessageFormatter(reviews);

  const stories = pickSectionStories(sectionData);

  const isSingle = stories.length === 1;
  const isWrappedContent = !!sectionData?.wrapContent;

  return (
    <>
      <div
        className={cn(
          "flex flex-col max-h-screen overflow-hidden",
          "laptop:h-full laptop:max-h-none",
          {
            "h-screen": !promotionBannerEnabled,
            "h-[calc(100vh-49px)]": promotionBannerEnabled,
          },
        )}
        style={sectionData.backgroundFull ? backgroundStyles : {}}
      >
        <div className="flex flex-row">
          <section
            className={cn(
              "shrink grow flex justify-center",
              "laptop:w-1/2 laptop:h-full laptop:py-24",
              "laptop:justify-end laptop:pr-40",
            )}
            style={sectionData.backgroundFull ? {} : backgroundStyles}
          >
            <div
              className={cn(
                "flex justify-center grow relative h-full",
                "laptop:justify-end laptop:min-h-[616px]",
              )}
            >
              <PhoneFrame desktopOnly>
                <MediaPlaylist
                  loopMedia={isSingle}
                  playlist={stories}
                  style={StoriesVariation.INSTAGRAM}
                />
              </PhoneFrame>
            </div>
          </section>

          <ThemeWrapper
            className="hidden laptop:flex w-1/2 self-stretch items-center"
            theme={sectionData.backgroundFull ? "inherit" : "base"}
          >
            <div
              className={cn(
                "w-full h-full flex justify-center items-center px-16",
                "1.5xl:w-[640px] 1.5xl:pr-0",
                "2xl:w-[683px]",
                "3xl:w-[720px]",
                "4xl:w-[768px]",
                {
                  "pl-16": !sectionData.backgroundFull,
                },
              )}
            >
              <div className="flex flex-col w-full">
                <Card
                  className={cn(
                    "text-left mb-8 pb-[30px] pr-3.5 !border-transparent",
                    {
                      "bg-primary text-primary-content": isWrappedContent,
                      "bg-transparent text-base-content p-0": !isWrappedContent,
                    },
                  )}
                >
                  <div>
                    <StoriesBadge
                      theme={
                        // This is a XOR between these conditions. We'll have base theme if they're both true or both false
                        (!sectionData.backgroundFull ||
                          sectionData.theme === "base") !== isWrappedContent
                          ? "base"
                          : "primary"
                      }
                    />
                  </div>

                  {title && (
                    <h1
                      className={cn(
                        "font-display font-bold text-5xl leading-tight mt-3",
                        {
                          "mb-1": !!reviewsMessage,
                          "mb-4": !reviewsMessage,
                        },
                      )}
                    >
                      <TextWithVariables value={title} />
                    </h1>
                  )}

                  <div>
                    {reviewsMessage && (
                      <div className="mb-4 text-md flex items-center gap-1">
                        <Stars amount={5} />

                        <div className="font-semibold font-display ml-2 mt-0.5">
                          <TextWithVariables value={reviewsMessage} />
                        </div>
                      </div>
                    )}

                    {subtitle && (
                      <div className="italic mb-6 text-lg leading-tight font-normal font-body md:w-5/6 opacity-70">
                        <TextWithVariables value={subtitle} />
                      </div>
                    )}
                  </div>
                </Card>

                <DesktopCallToAction sectionData={sectionData} />
              </div>
            </div>
          </ThemeWrapper>
        </div>
      </div>

      {subtitle && (
        <div
          className={cn("bg-base text-base-content py-6 px-4", "laptop:hidden")}
        >
          <Card
            className={cn("text-left", {
              "bg-primary text-primary-content": isWrappedContent,
              "bg-transparent border-transparent": !isWrappedContent,
            })}
          >
            <Stars amount={5} className="!justify-start mb-4" />

            <div className="font-body font-normal italic text-lg mb-4">
              <TextWithVariables value={subtitle} />
            </div>

            <StoriesBadge
              theme={
                (sectionData.theme === "base") !== isWrappedContent
                  ? "base"
                  : "primary"
              }
            />
          </Card>
        </div>
      )}
    </>
  );
};

export default StoriesInstagramSection;
